import './Kontakt.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import person_img from "../../assets/images/default-person.png";
import React from "react";
import logo_irchenhauser from "../../assets/images/logo_irchenhauser.jpg"
import logo_wenger from '../../assets/images/wenger.png'

export default function Kontakt() {
  return (
      <Container>
        <section>
          <h1>Kontakt</h1>

          <p>Sie haben Fragen zu unseren Leistungen? <br/>
            Wir beraten Sie gerne zu Ihrem Bauvorhaben oder erstellen Ihnen ein unverbindliches Angebot.
          </p>

        </section>

        <section>
          <address>
            <Row>
              <Col md={6} className="d-flex justify-content-center">
                <div className="contact">
                  <Image className="float-left mr-3" height="80px" roundedCircle={true} src={person_img}/>
                  <strong>Ludwig Brunner</strong>
                  <br/>
                  <span>+49 176 32873420</span>
                  <br/>
                  <a href="mailto:brunner@bw-wohnbau.com">brunner@bw-wohnbau.com</a>
                </div>
              </Col>
              <Col md={6} className="d-flex justify-content-center">
                <div className="contact">
                  <Image className="float-left mr-3" height="80px" roundedCircle={true} src={person_img}/>
                  <strong>Johannes Wenger</strong>
                  <br/>
                  <span>+49 171 1851221</span>
                  <br/>
                  <a href="mailto:wenger@bw-wohnbau.com">wenger@bw-wohnbau.com</a>
                </div>
              </Col>
            </Row>
          </address>
        </section>

        <section>
          <p className="address">
            <strong>B & W Wohnbau GmbH</strong><br/>
            Blumenstraße 9<br/>
            86561 Aresing<br/>
            <a href="mailto:info@bw-wohnbau.com">info@bw-wohnbau.com</a>
          </p>
        </section>

        <section>
          <h2>Unsere Partner</h2>
          <Row className="mt-5">
            <Col md={6} className="d-flex flex-column justify-content-center">
              <a rel="noreferrer" target="_blank" href="https://www.isi-immobilien.de/">
                <Image className="logo-irchenhauser" src={logo_irchenhauser} />
              </a>
            </Col>
            <Col md={6}>
              <a rel="noreferrer" target="_blank" href="https://www.wenger-bau.de/">
                <Image className="logo-wenger mt-5 mt-md-0" src={logo_wenger} />
              </a>
            </Col>
          </Row>

        </section>
      </Container>
  )
}