import React from 'react'

import titel_aresing from '../../assets/images/titel_aresing.jpg'
import titel_aresing_autenzell from '../../assets/images/titelbild_aresing-autenzell.jpg'
import titel_waidhofen from '../../assets/images/titelbild_waidhofen.jpg'
import person_img from '../../assets/images/default-person.png'
import baugrund_img from '../../assets/images/buw_baugrund.jpg'
import './Home.css'
import Header from "../../components/header/Header";
import {Badge, Col, Image, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

export default function Home() {

    return (
        <div>
          <Header/>

          <section className={'behind'}>
            <Image className={'img-baugrund'} alt="Baugrund gesucht" src={baugrund_img}/>
          </section>

          <Container>
            <Row>
              <Col>
                <section>
                    <h1>Aktuelle Projekte</h1>

                      <div className="expose">
                        <h2>Aresing</h2>
                        <img alt="" src={titel_aresing}/>

                        <p>Moderne Doppelhaushälfte mit hochwertiger Ausstattung in schöner Lage von Aresing.<br/>
                          125,32m² Wohnfläche / 287 m² Grundstück.<br/>
                          Videosprechanlage, elektische Rolläden, Garage und Stellplatz<br/><br/>
                          <strong>Kaufpreis 545.000€</strong>
                        </p>

                        <a href="/expose/aresing.pdf" target="_blank">&raquo; Hier gehts zum Expos&eacute;</a>
                      </div>

                    <div className="expose">
                      <h2>Aresing-Autenzell <Badge variant="danger">Verkauft</Badge></h2>
                      <img alt="" src={titel_aresing_autenzell}/>

                        <p>Moderne Doppelhaushälfte in naturnaher Lage von Aresing-Autenzell.<br/>
                          116m² Wohnfläche / 370 m² Grundstück.<br/>
                          Videosprechanlage, elektische Rolläden, Echtholzboden und hochwertige Fliesen<br/><br/>
                          <strong>Kaufpreis <span className="sold">434.000€</span> <Badge variant="danger">Verkauft</Badge></strong>
                        </p>

                    </div>

                    <div className="expose">
                      <h2>Waidhofen <Badge variant="danger">Verkauft</Badge></h2>
                      <img alt="" src={titel_waidhofen}/>

                        <p>Großzügige Doppelhäuser mit Einfamilienhaus-Charakter in ruhiger Lage von Waidhofen.<br/>
                          136 m² Wohnfläche / 407 m² Grundstück<br/>
                          Viedeosprechanlage, elektrische Rolläden, Echtholzboden und hochwertige Fliesen<br/><br/>
                          <strong>Kaufpreis <span className="sold">539.000€</span> <Badge variant="danger">Verkauft</Badge></strong>
                        </p>

                    </div>
                </section>
              </Col>
            </Row>
            <section>
              <address>
                <Row>
                  <Col md={6} className="d-flex justify-content-center">
                    <div className="contact">
                      <Image className="float-left mr-3" height="80px" roundedCircle={true} src={person_img}/>
                      <strong>Ludwig Brunner</strong>
                      <br/>
                      <span>+49 176 32873420</span>
                      <br/>
                      <a href="mailto:brunner@bw-wohnbau.com">brunner@bw-wohnbau.com</a>
                    </div>
                  </Col>
                  <Col md={6} className="d-flex justify-content-center">
                    <div className="contact">
                      <Image className="float-left mr-3" height="80px" roundedCircle={true} src={person_img}/>
                      <strong>Johannes Wenger</strong>
                      <br/>
                      <span>+49 171 1851221</span>
                      <br/>
                      <a href="mailto:wenger@bw-wohnbau.com">wenger@bw-wohnbau.com</a>
                    </div>
                  </Col>
                </Row>
              </address>
            </section>
          </Container>
        </div>
    )
}
