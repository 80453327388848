import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar"
import logo from '../assets/images/logo_no_text.svg'
import {Link} from "react-router-dom";

export default function NavBar() {

  return (
    <Navbar expand="md" sticky="top" bg="light">
      <Navbar.Brand href="#home">
        <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="BW Wohnbau Logo"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Item className="mr-4"><Link to="/">Start</Link></Nav.Item>
          <Nav.Item className="mr-4"><Link to="/leistungen">Leistungen</Link></Nav.Item>
          <Nav.Item className="mr-4"><Link to="/referenzen">Referenzen</Link></Nav.Item>
          <Nav.Item><Link to="/kontakt">Kontakt</Link></Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}