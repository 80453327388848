import './App.css';
import Home from "./pages/home/Home";
import NavBar from "./components/NavBar";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Leistungen from "./pages/leistugen/Leistungen";
import Kontakt from "./pages/kontakt/Kontakt";
import Referenzen from "./pages/referenzen/Referenzen";
import Footer from "./components/footer/Footer";
import Impressum from "./pages/impressum/Impressum";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
      <Router>
        <ScrollToTop />
        <NavBar/>

        <Route exact path="/" component={Home}/>
        <Route path="/leistungen" component={Leistungen}/>
        <Route path="/referenzen" component={Referenzen}/>
        <Route path="/kontakt" component={Kontakt}/>
        <Route path="/impressum" component={Impressum}/>

        <Footer />
      </Router>
  );
}

export default App;
