import './Leistungen.css'
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Leistungen() {
  return (
      <Container>
        <Row>
          <Col>
            <section>
              <h1>Leistungen</h1>

              <div className="l-table-wrapper">
                <Row>
                  <Col xs={12} md={6}>
                    <div className="leistung mb-4">
                      <h3>Bauträger</h3>
                      <p>Als Bauträger realisieren wir auf eigenen Grundstücken schlüsselfertige Bauprojekte.
                        Dabei spielt es für uns keine Rolle, ob Einfamilienhaus, Mehrfamilienhaus oder andere Haustypen.
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="leistung mb-4">
                      <h3>General&shy;übernehmer</h3>
                      <p>
                        Wir bauen auf Ihrem Grundstück schlüsselfertig. Vom Einfamilienhaus bis hin zum
                        Mehrfamilienhaus.
                        Wir unterstützen Sie schon in der Planungsphase und setzen dabei Ihre Wünsche exakt um.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <div className="leistung mt-4">
                      <h3>Baubetreuung</h3>
                      <p>
                        Um ein Bauvorhaben zielsicher abzuschließen, braucht es die richtige Bauüberwachung:
                        organisiert, vorausschauend und wachsam. Gerne kümmern wir uns auch um Ihr Bauvorhaben.
                      </p>
                    </div>
                  </Col>

                  <Col xs={12} md={6}>
                    <div className="leistung mt-4">
                      <h3>Immobilien&shy;ver&shy;mittlung</h3>
                      <p>
                        Wir vermitteln Ihr Grundstück oder Immobilie.
                        Dabei arbeiten wir eng mit unserem Partner "Irchenhauser und Spreng Immobilien" zusammen.
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>

            </section>
          </Col>
        </Row>
      </Container>
  )
}