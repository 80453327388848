import './Footer.css'
import React from "react";
import {Link} from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";

export default function Footer() {

  return (
    <footer>
      <Row>
        <Col md={4} className="mb-3 mb-md-0">
          <span className="mr-2">Follow us on</span>
          <a className={'insta-link'} target={'_blank'} rel="noreferrer" href={'https://www.instagram.com/bw_wohnbau/ '}>
            <FontAwesomeIcon size={'2x'} icon={faInstagram}/>
          </a>
        </Col>
        <Col md={4} className="mb-3 mb-md-0">
          <p className="no-address">
            <strong>B & W Wohnbau GmbH</strong>
            <br/>
            Blumenstraße 9<br/>
            86561 Aresing<br/>
            <a href="mailto:info@bw-wohnbau.com">info@bw-wohnbau.com</a>
          </p>
        </Col>
        <Col md={4}>
          <Link to="/impressum" >Impressum</Link>
        </Col>
      </Row>
    </footer>
)
}