import logo from "../../assets/images/logo_text-below.svg";
import './Header.css'
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

export default function Header() {

  return (
      <Container fluid>
        <Row>
          <Col>
            <header>
                <img alt="" src={logo} className="logo"/>
              <p className="intro-text">&raquo; Die B & W Wohnbau GmbH ist ein junges dynamisches Team und Ihr Partner im
                Bereich Immo&shy;bilien&shy;vermit&shy;tlung und Schlüs&shy;sel&shy;fertig&shy;bau in und um Schrobenhausen &laquo;</p>
            </header>
          </Col>
        </Row>
      </Container>
  )
}