import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Impressum() {
  return (
      <Container>
        <Row>
          <Col className="mb-5">
            <h1 className="mt-3">Impressum</h1>

            <p>Verantwortlich für den Inhalt:</p>
            <p>
              B & W Wohnbau GmbH<br/>
              Blumenstraße 9<br/>
              86561 Aresing<br/>
              Telefon: 08252 6028<br/>
              <a href="mailto:info@bw-wohnbau.com">info@bw-wohnbau.com</a>
            </p>
            <p>
              Geschäftsführer: Ludwig Brunner und Johannes Wenger
            </p>
            <p>
              Sitz und Amtsgericht: Ingolstadt, Handelsregisternummer: HRB 8728 Steuer ID: 124/122/00975
            </p>
            <p>
              Nach § 5 TMG, erlaubnispflichtige Tätigkeit nach § 34c Absatz 1 Satz 1 Nummer 1 (Immobilienmakler) 3a (Bauträger)
              und 3b (Baubetreuer) GewO
              Aufsichtsbehörde: Industrie-und Handelskammer für München und Oberbayern, Max-Joseph-Str. 2, 80333 München (ab
              01.01.2020)
            </p>
            <p>
              Copyright Bilder: B&W Wohnbau GmbH www.bw-wohnbau.com
            </p>
            <p>
              Haftungsausschluss: Wir bemühen uns, die Inhalte unserer Seite aktuell zu halten. Trotz sorgfältiger Bearbeitung
              bleibt eine Haftung ausgeschlossen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
              Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
              verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen. Bei bekannt werden von
              Rechtsverletzungen werden wir diese Inhalte umgehend entfernen. Eine diesbezügliche Haftung übernehmen wir erst ab
              dem Zeitpunkt der Kenntnis einer möglichen Rechtsverletzung.
            </p>
            <p>
              Haftung für Links: Unser Angebot enthält Links zu Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
              Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Für
              die Inhalte und die Richtigkeit der Informationen verlinkter Websites fremder Informationsanbieter wird keine Gewähr
              übernommen. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße OHNE BEANSTANDUNG
              überprüft. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen. Für alle Links
              auf dieser Homepage gilt: Wir distanzieren uns hiermit ausdrücklich von allen Inhalten aller verlinkten
              Seitenadressen auf unserer Homepage und machen uns diese Inhalte nicht zu eigen.
            </p>
            <p>Urheberrecht: Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
              deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
              Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.</p>
            <p>Datenschutz: Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
              Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
              Eine Vertraulichkeit im Hinblick auf die Datenschutzbestimmungen wird nur unter der vorstehenden Einschränkung
              gewährleistet. Insbesondere sollen alle Mitteilungen von personenbezogenen Daten über das Internet nur erfolgen,
              soweit nicht Rechte Dritter berührt werden. Es sei denn der Dritte hat in Kenntnis der vorstehenden
              Sicherheitslücken ebenfalls seine Zustimmung erklärt. Eine Haftung des Seitenbetreibers wird für die durch solche
              Sicherheitslücken entstehenden Schäden oder Unterlassungsansprüche ausgeschlossen. Der Nutzung von allen
              veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung wird
              widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
              Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.</p>
            <p>Registrierung der Zugriffe: Alle Zugriffe auf diese Homepage werden registriert. Es werden Datum, Rechneradresse
              und die Anzahl der gelesenen Dokumente gespeichert. Diese Daten werden unter Umständen für statistische Zwecke
              ausgewertet. Mit der Benutzung der Homepage erklären Sie sich hiermit einverstanden. Sollten Sie mit dieser
              Vorgehensweise nicht einverstanden sein, so steht es Ihnen frei, dieses Internet-Angebot nicht zu benutzen.</p>
            <p>Verbraucherinformationen: Die B&W Wohnbau gmbh ist grundsätzlich weder bereit noch dazu verpflichtet, an
              Streitbeilegungsverfahren vor der Verbraucherschlichtungsstelle teilzunehmen.</p>
            <p>Allgemeine Verbraucherschlichtungsstelle des Zentrums für Schlichtung e.V. Straßburger Str. 8,77694 Kehl Telefon:
              +49 7851 79579 40 Telefax: +49 7851 79579 41 Internet: www.verbraucher-schlichter.de E-Mail:
              mail@verbraucher-schlichter.de Online-Streitbeilegung gemäß Art. 14 Abs. 1 ODR-VO: Die Europäische Kommission stellt
              eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden: http://ec.europa.eu/consumers/odr</p>
            <p>Verbraucherinformation zur Schlichtungsstelle: Ombudsmann Immobilien im IVD:Wolfgang Ball Ombudsstelle
              –Littenstraße 10, 10179 Berlin Fax: 030 / 27 57 26 78 E-Mail: info@ombudsmann-immobilien.net
              www.ombudsmann-immobilien.net</p>
          </Col>
        </Row>
      </Container>
  )
}