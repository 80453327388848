import './Referenzen.css'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import img from "../../assets/images/dummy_ref.jpg"

export default function Referenzen() {

  const references = [
    {
      title: "Renovierung Einfamilienhaus Schrobenhausen Goethestraße",
      images: [
        '/images/renov_efh_sob/1.jpg',
        '/images/renov_efh_sob/2.jpeg',
        '/images/renov_efh_sob/3.jpeg',
        '/images/renov_efh_sob/4.jpg',
        '/images/renov_efh_sob/5.jpg',
        '/images/renov_efh_sob/6.jpg',
        '/images/renov_efh_sob/7.jpeg',
        '/images/renov_efh_sob/8.jpg',
        '/images/renov_efh_sob/9.jpg',
        '/images/renov_efh_sob/11.jpg',
        '/images/renov_efh_sob/12.jpg'
      ]
    },
    {
      title: "Neubau Einfamilienhaus Aresing Neubaugebiet Hänggasse",
      images: [
        '/images/efh_aresing_haenggasse/one.jpg',
        '/images/efh_aresing_haenggasse/two.jpg',
        '/images/efh_aresing_haenggasse/three.jpg',
        '/images/efh_aresing_haenggasse/four.jpg',
        '/images/efh_aresing_haenggasse/five.jpg'
      ]
    },
    {
      title: "Neubau Doppelhäuser Autenzell-Aresing",
      images: [
        '/images/dh_autenzell/one.jpg',
        '/images/dh_autenzell/two.jpg',
        '/images/dh_autenzell/three.jpg',
        '/images/dh_autenzell/four.jpg'
      ]
    },
    {
      title: "Neubau Einfamilienhaus Sielenbach Samweg",
      images: [
        '/images/efh_sielenbach/one.jpg',
        '/images/efh_sielenbach/two.jpg',
        '/images/efh_sielenbach/three.jpg',
        '/images/efh_sielenbach/four.jpg',
        '/images/efh_sielenbach/five.jpg'
      ]
    },
    {
      title: "Neubau Einfamilienhaus Aresing Bauernstraße",
      images: [
        '/images/efh_aresing_bauernstrasse/one.jpg',
        '/images/efh_aresing_bauernstrasse/two.jpg',
        '/images/efh_aresing_bauernstrasse/three.jpg'
      ]
    }
  ]

  const refCards = references.map((reference, index) =>
    <Col md={6} key={"ref-col-" + index}>
      <Card className="card">
        <Card.Header variant="top">
          <Card.Title>{reference.title}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Carousel interval={null}>
            {
              reference.images.map((src, index) =>
                <Carousel.Item key={index}>
                  <img
                      className="d-block w-auto carousel-image"
                      src={src}
                      alt={index + '. Slide'}
                  />
                </Carousel.Item>)
            }
          </Carousel>
        </Card.Body>
      </Card>
    </Col>
  )



  return (
      <Container>
        <Row>
          <Col>
            <section>
              <h1>Referenzen</h1>

              <div className="l-content-wrapper">
                <Row>
                  {refCards}
                </Row>
              </div>
            </section>
          </Col>
        </Row>
      </Container>
  )
}